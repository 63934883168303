class IsInView {

    constructor(animations = null, selector = '[data-iiv]', defaultThreshold = 0, defaultRootMargin = '0px 0px -20% 0px') {

        this.elements = document.querySelectorAll(selector);

        if (this.elements.length > 0) {
            this.elements.forEach(element => {

                let options = {
                    threshold: defaultThreshold,
                    rootMargin: defaultRootMargin
                };

                if (element.hasAttribute('data-iiv-threshold')) {
                    options.threshold = element.getAttribute('data-iiv-threshold');
                }
                if (element.hasAttribute('data-iiv-rootmargin')) {
                    options.rootMargin = element.getAttribute('data-iiv-rootmargin');
                    options.threshold = 0;
                }

                let observer = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add('is-inview');

                            if (animations && entry.target.hasAttribute('data-iiv-animation') && !entry.target.hasAttribute('data-iiv-animated')) {
                                entry.target.setAttribute('data-iiv-animated', true);
                                animations(entry.target.getAttribute('data-iiv-animation'), entry.target);
                            }
                        }
                        else {
                            if (entry.target.hasAttribute('data-iiv-repeat')) {
                                entry.target.classList.remove('is-inview');
                            } else {
                                // If element is already visible and not reapeatable, unbsorve.
                                if (entry.target.classList.contains('is-inview')){
                                    observer.unobserve(entry.target);
                                }
                            }
                        }
                    })
                }, options);

                observer.observe(element);

            });
        }
    }

}

export default IsInView;