import anime, { stagger } from 'animejs';
import Swiper from 'swiper/bundle';
import Rellax from 'rellax';
import Utils from "../../utils/Utils";
import IsInView from "../../utils/IsInView";
import SplitText from "../../utils/SplitText";

// Load image async
const loadImages = (parent = document) => {
    parent.querySelectorAll('.imageRender:not(.imageRender--loaded)').forEach((img) => {
        let url = img.getAttribute('data-url');
        let cover = img.querySelector('.imageRender_cover');

        Utils.loadImage(url).then(() => {
            cover.style.backgroundImage = 'url(' + url + ')';
            img.classList.add('imageRender--loaded');
        });
    });
};

const scrollAnimations = (animation, element) => {
    if (animation == 'title') {
        setTimeout(() => {
            element.style.opacity = 1;
            anime({
                targets: element.querySelectorAll('.splitted-letter-inner'),
                translateY: ["101%", "0%"],
                delay: anime.stagger(15),
                duration: 800,
                easing: 'easeOutExpo'
            });
        }, 300);
    }

    if (animation == 'counter') {
        const speed = 1500;
        const animateCounter = () => {
            const value = +element.getAttribute('data-value');
            const data = +element.innerText;

            const time = value / speed;
            if (data < value) {
                element.innerText = Math.ceil(data + time);
                setTimeout(animateCounter, speed/value);
            } else {
                element.innerText = value;
            }
        }
        setTimeout(() => {
            animateCounter();
        }, 300);
    }

    if (animation == 'type') {
        const baseText = element.innerText;
        const letters = baseText.split("");
        let i = 0;
        element.style.height = element.offsetHeight + "px";
        element.innerText = '';

        const typeSentence = () => {
            setTimeout(() => {
                if (i < letters.length) {
                    element.innerHTML += letters[i];
                    i++;
                    typeSentence();
                }
            }, Utils.randomInt(30,50));
        };

        element.style.opacity = 1;
        typeSentence();
    }
}

// JS CUSTOM HERE
const page = () => {

    console.log("Hello world template B.");

    loadImages();
    
    new SplitText();
    new IsInView(scrollAnimations);

    let rellax = new Rellax('.rellax', { center : true });

    new Swiper(".swiperProduct1", {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        loop: true,
        coverflowEffect: {
            rotate: 0,
            stretch: 30,
            depth: 500,
            modifier: 1,
        }
    });

    new Swiper(".swiperProduct2", {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        loop: true,
        coverflowEffect: {
            rotate: 0,
            stretch: 30,
            depth: 500,
            modifier: 1,
        }
    });

    let swiperProduct3Thumbs = new Swiper(".swiperProduct3Thumbs", {
      spaceBetween: 10,
      slidesPerView: 4,
      watchSlidesProgress: true,
    });
    let swiperProduct3 = new Swiper(".swiperProduct3", {
        spaceBetween: 10,
        thumbs: {
            swiper: swiperProduct3Thumbs,
        },
    });
    
};

const initPage = () => {
    page();
};

(function () {
    Utils.preloadHTMLImages(document.body.innerHTML).then(() => {
        initPage();
    });
})();