class SplitText {
    constructor(selector = '[data-split]') {
        if (document.querySelectorAll(selector).length) {
            document.querySelectorAll(selector).forEach((elt) => {
                this.split(elt);
            });
        }
    }

    createSpan(_class) {
        let span = document.createElement('span');
        span.className = _class;
        return span;
    }

    split(elt) {
        let containerArray = new Array;
        const splittedElt = elt.innerText.split(' ');
        let counter = splittedElt.length;
        splittedElt.map(word => {
            const wrapper = this.createSpan('splitted-word');
            word.split(/(?!^)/).map(char => {
                let el = this.createSpan('splitted-letter');
                el.innerHTML = '<span class="splitted-letter-inner">' + char + '</span>';
                wrapper.appendChild(el);
            });
            counter--;
            containerArray.push(wrapper);
        });

        elt.innerHTML = '';
        containerArray.forEach(child => {
            elt.appendChild(child);
        });
    }
}

export default SplitText;